import './webevolvement-calculator-for-experiencesol.scss'
import "parsleyjs";
import Swal from "sweetalert2";
import 'bootstrap/js/src/modal';

function updateTotalPrice() {
	let sum = 0;

	// Loop through each element with the class .selected-item-price
	document.querySelectorAll(".selected-item-price").forEach((element) => {
		let priceText = element.textContent;

		// Check if the text content is a number
		if (!isNaN(priceText) && priceText.trim() !== "") {
			// Add the number to the sum
			sum += parseFloat(priceText);
		}
	});

	// Output the result to an element with the ID js-we-price-total
	document.getElementById("js-we-price-total-amount").textContent =
		sum.toFixed(2);
}

function updateSectionPrices() {
var selectedText1 = jQuery('#select-booth-size').find("option:selected").text();
	if (jQuery('#select-booth-size').val() !== "" && jQuery('#select-booth-size').val() !== null && jQuery('#select-booth-size').val() !== "0") {
		jQuery("#js-we-selected-booth-size").text(selectedText1);
	} else {
		jQuery("#js-we-selected-booth-size").text('-');
	}

		var selectedPrice1 = jQuery('#select-booth-size').find("option:selected").data("price-per-sqare-meter");
		var selectedSquare1 = jQuery('#select-booth-size').find("option:selected").data("square-meters");
		if (jQuery('#select-booth-size').val() !== "" && jQuery('#select-booth-size').val() !== null && jQuery('#select-booth-size').val() !== "0") {
		jQuery("#js-we-price-booth-size").html(
			'&#8364 <span class="selected-item-price">' + Number(selectedPrice1*selectedSquare1) + "</span>"
		);
		} else {
			jQuery("#js-we-price-booth-size").html(
			'&#8364 <span class="selected-item-price">' + '-' + "</span>"
		);
		}


		var selectedText2 = jQuery('#select-structure-type').find("option:selected").text();
		if (jQuery('#select-structure-type').val() !== "" && jQuery('#select-structure-type').val() !== null && jQuery('#select-structure-type').val() !== "0") {
		jQuery("#js-we-selected-structure-type").text(selectedText2);
		} else {
			jQuery("#js-we-selected-structure-type").text('-');
		}

		var selectedPrice2 = jQuery('#select-structure-type').find("option:selected").data("price-per-sqare-meter-per-square-meter");
		var selectedSquare2 = jQuery('#select-booth-size').find("option:selected").data("square-meters");

		if (jQuery('#select-structure-type').val() !== "" && jQuery('#select-structure-type').val() !== null && jQuery('#select-structure-type').val() !== "0") {
		jQuery("#js-we-price-structure-type").html(
			'&#8364 <span class="selected-item-price">' + Number(selectedPrice2*selectedSquare2) + "</span>"
		);
		} else {
			jQuery("#js-we-price-structure-type").html(
			'&#8364 <span class="selected-item-price">' + '-' + "</span>"
		);
		}


		var selectedText3 = jQuery('#select-furniture').find("option:selected").text();
if (jQuery('#select-furniture').val() !== "" && jQuery('#select-furniture').val() !== null && jQuery('#select-furniture').val() !== "0") {
		jQuery("#js-we-selected-furniture-type").text(selectedText3);
} else {
	jQuery("#js-we-selected-furniture-type").text('-');
}

		var selectedPrice3 = jQuery('#select-furniture').find("option:selected").data("price-per-sqare-meter-per-square-meter");
		var selectedSquare3 = jQuery('#select-booth-size').find("option:selected").data("square-meters");
		if (jQuery('#select-furniture').val() !== "" && jQuery('#select-furniture').val() !== null && jQuery('#select-furniture').val() !== "0") {
		jQuery("#js-we-price-furniture-type").html(
			'&#8364 <span class="selected-item-price">' + Number(selectedPrice3*selectedSquare3) + "</span>"
		);
		} else {
			jQuery("#js-we-price-furniture-type").html(
			'&#8364 <span class="selected-item-price">' + '-' + "</span>"
		);
		}

		var selectedText4 = jQuery('#select-flooring').find("option:selected").text();
		if (jQuery('#select-flooring').val() !== "" && jQuery('#select-flooring').val() !== null && jQuery('#select-flooring').val() !== "0") {
		jQuery("#js-we-selected-flooring").text(selectedText4);
		} else {
			jQuery("#js-we-selected-flooring").text('-');
		}

		var selectedPrice4 = jQuery('#select-flooring').find("option:selected").data("price-per-sqare-meter-per-square-meter");
		var selectedSquare4 = jQuery('#select-booth-size').find("option:selected").data("square-meters");
		if (jQuery('#select-flooring').val() !== "" && jQuery('#select-flooring').val() !== null && jQuery('#select-flooring').val() !== "0") {
		jQuery("#js-we-price-flooring").html(
			'&#8364 <span class="selected-item-price">' + Number(selectedPrice4*selectedSquare4) + "</span>"
		);
		} else {
			jQuery("#js-we-price-flooring").html(
			'&#8364 <span class="selected-item-price">' + '-' + "</span>"
		);
		}
}

jQuery(function () {
	jQuery("#select-booth-size").on("change", function () {
		updateSectionPrices();
		updateTotalPrice();
	});
	jQuery("#select-structure-type").on("change", function () {
		updateSectionPrices();
        updateTotalPrice();
	});
	jQuery("#select-furniture").on("change", function () {
		updateSectionPrices();
        updateTotalPrice();
	});
	jQuery("#select-flooring").on("change", function () {
		updateSectionPrices();
        updateTotalPrice();
	});
});


//FORM PROCESSING ON BTN CLICK
jQuery(function () {
	var form = $("#we-calculatior-form").parsley();
	jQuery("#we-calculator-submit").on("click", function (e) {
		e.preventDefault();

		jQuery(".we-calculatior").append(
			'<div class="we-loading" style=""><div class="we-loading__image-container h-100"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; background: transparent; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"><circle cx="50" cy="50" fill="none" stroke="#f6b81d" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></div></div>'
		);

		form.validate();

		if (form.isValid()) {

		jQuery("#we-validation-error-message").text('');


		var self = jQuery(this);

		var selectedBoothSize = jQuery("#select-booth-size")
			.find("option:selected")
			.text();
		var selectedStructureType = jQuery("#select-structure-type")
			.find("option:selected")
			.text();
		var selectedFurnitureType = jQuery("#select-furniture")
			.find("option:selected")
			.text();
		var selectedFlooring = jQuery("#select-flooring").find("option:selected").text();

		var selectedBoothSizePrice = jQuery("#js-we-price-booth-size .selected-item-price").text();
		var selectedStructureTypePrice = jQuery("#js-we-price-structure-type .selected-item-price").text();
		var selectedFurnitureTypePrice = jQuery("#js-we-price-furniture-type .selected-item-price").text();
		var selectedFlooringPrice = jQuery("#js-we-price-flooring .selected-item-price").text();

		var totalCost = jQuery("#js-we-price-total-amount").text();

		var clientName = jQuery("#name").val();
		var clientEmail = jQuery("#email-id").val();
		var clientMessage = jQuery("#we-message").val();

		jQuery.ajax({
			url: ajax_object.ajaxurl,
			type: "POST",
			dataType: "html",
			data: {
				action: "we_calculator_process_form",
				selected_booth_size: selectedBoothSize,
				selected_structure_type: selectedStructureType,
				selected_furniture_type: selectedFurnitureType,
				selected_flooring: selectedFlooring,

				selected_booth_size_price: selectedBoothSizePrice,
				selected_structure_type_price: selectedStructureTypePrice,
				selected_furniture_type_price: selectedFurnitureTypePrice,
				selected_flooring_price: selectedFlooringPrice,

				total_cost: totalCost,

				client_name: clientName,
				client_email: clientEmail,
				client_message: clientMessage,
				security: ajax_object.ajax_nonce,
			},
			success: function (data) {
				var response = JSON.parse(data);
				console.log("ajax done succesful");
				console.log(response);

				jQuery(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "success",
					showCloseButton: false,
					title: "Message sent!",
					text: "",
					timer: 2500,
					showConfirmButton: false,
					html: "<p>Your message has been sent. We will contact you soon.</p>",
					willClose: () => {
						jQuery("#we-calculator-modal").modal("hide");
						jQuery("#we-calculatior-form")[0].reset();
						jQuery(".selected-item-price").text("0");
						jQuery("#js-we-selected-booth-size, #js-we-selected-structure-type, #js-we-selected-furniture-type, #js-we-selected-flooring").text("-");
					},
				});

				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
			error: function (data) {
				//var results = JSON.parse(data);
				console.log("ajax error");
				console.log(data);
				jQuery(".we-loading").remove();
				Swal.fire({
					position: "center-center",
					icon: "error",
					showCloseButton: true,
					title: "Ups!",
					text: "",
					timer: 2500,
					showConfirmButton: false,
					html: "<p>Something went wrong. Your message was not sent.</p>",
					//willClose: redirectToTasks,
				});
				if (window.history.replaceState) {
					window.history.replaceState(null, null, window.location.href);
				}
			},
		});
	} else {
		jQuery(".we-loading").remove();
		jQuery("#we-validation-error-message").text('Please fill required fields.');

		form.on('field:success', function (fieldInstance) {
			jQuery("#we-validation-error-message").text("");
		})
	} //parsley valid
	});
});
